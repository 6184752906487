import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    isAuthenticated: false,
  }),
  actions: {
    login(userData) {
      this.user = userData;
      this.isAuthenticated = true;
    },
    logout() {
      this.user = null;
      this.isAuthenticated = false;
    },
  },
  persist: {
    key: "user-store",
    storage: localStorage,
    paths: ["user", "isAuthenticated"], // 指定需要持久化的字段
  },
});
