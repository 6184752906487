<template>
    <div class="product">
        <h1>产品 {{ $route.params.id }}</h1>
        <p>这是产品 {{ $route.params.id }} 的详情页面。</p>
    </div>
</template>

<script>
export default {
    name: 'Product'
}
</script>