<template>
    <li :class="{
        'dropdown': item.children,
        'active': isActive,
        'parent-active': isChildActive || isOpen
    }" @mouseenter="openMenu" @mouseleave="closeMenu">
        <template v-if="item.children">
            <a href="#" class="nav-link" @click.prevent>
                {{ item.title }}
                <span class="arrow">▾</span>
            </a>
            <ul class="dropdown-menu" v-show="isOpen">
                <TopNavbarItem v-for="child in item.children" :key="child.name" :item="child" :level="level + 1"
                    @active-changed="handleChildActiveChange" />
            </ul>
        </template>
        <router-link v-else :to="item.path" active-class="active" :class="['nav-link', item.class]">
            {{ item.title }}
        </router-link>
    </li>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    name: 'TopNavbarItem',
    props: {
        item: Object,
        level: {
            type: Number,
            default: 0
        }
    },
    emits: ['active-changed'],
    setup(props, { emit }) {
        const route = useRoute();
        const isOpen = ref(false);
        const isChildActive = ref(false);

        const isActive = computed(() => {
            return route.path === props.item.path;
        });

        const openMenu = () => {
            isOpen.value = true;
            emit('active-changed', true);
        };

        const closeMenu = () => {
            isOpen.value = false;
            if (!isChildActive.value) {
                emit('active-changed', false);
            }
        };

        const handleChildActiveChange = (active) => {
            isChildActive.value = active;
            emit('active-changed', active);
        };

        return {
            isOpen,
            isActive,
            isChildActive,
            openMenu,
            closeMenu,
            handleChildActiveChange
        };
    }
}
</script>

<style scoped>
li {
    position: relative;
    margin: 0 5px;
}

.nav-link {
    color: #333;
    text-decoration: none;
    font-size: 1.1em;
    padding: 10px 15px;
    display: block;
    transition: all 0.3s ease;
}

.nav-link:hover,
.nav-link:focus,
.active>.nav-link,
.nav-link.active {
    color: #3498db;
    background-color: rgba(52, 152, 219, 0.1);
}

/* 新增：父级菜单激活状态 */
.parent-active>.nav-link {
    color: #3498db;
    background-color: rgba(52, 152, 219, 0.05);
    /* 更浅的背景色 */
}

.arrow {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s ease;
    font-size: 18px;
    /* 增加箭头大小 */
}

.active>a>.arrow,
.parent-active>a>.arrow,
.dropdown:hover>a>.arrow {
    transform: rotate(180deg);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    min-width: 200px;
    border-radius: 4px;
    overflow: visible;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    list-style-type: none;
    padding: 0;
}

.dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.login-btn {
    background-color: #3498db;
    color: #ffffff;
    padding: 8px 15px;
    border-radius: 4px;
}

.login-btn:hover,
.login-btn:focus {
    background-color: #2980b9;
}

@media (max-width: 768px) {

    .dropdown-menu,
    .dropdown-menu .dropdown-menu {
        position: static;
        box-shadow: none;
        background-color: #f8f9fa;
    }

    .dropdown-menu .dropdown-menu {
        margin-left: 20px;
    }
}
</style>