<template>
    <nav class="navbar navbar-bottom">
        <div class="navbar-container">
            <ul class="nav-links">
                <li><router-link to="/" active-class="active">首页</router-link></li>
                <li><router-link to="/products/1" active-class="active">产品</router-link></li>
                <li><router-link to="/services/a" active-class="active">服务</router-link></li>
                <li><router-link to="/about" active-class="active">关于我们</router-link></li>
                <li><router-link to="/login" active-class="active">登录</router-link></li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'BottomNavbar'
}
</script>

<style scoped>
/* 底部导航栏特定样式 */
.navbar-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--navbar-bg);
    color: var(--navbar-text);
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
}

.nav-links {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

/* 其他样式保持不变 */
</style>