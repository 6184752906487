<template>
    <div class="login-page">
        <div class="login-container">
            <h2>登录</h2>
            <form @submit.prevent="handleLogin">
                <div class="form-group">
                    <label for="username">用户名：</label>
                    <input type="text" id="username" v-model="username" required>
                </div>
                <div class="form-group">
                    <label for="password">密码：</label>
                    <input type="password" id="password" v-model="password" required>
                </div>
                <button type="submit">登录</button>
            </form>
            <p v-if="error" class="error-message">{{ error }}</p>
            <p class="info-message">测试账号: admin, 密码: password</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '../store';

export default {
    name: 'Login',
    setup() {
        const username = ref('admin');  // 预填写用户名
        const password = ref('password');  // 预填写密码
        const error = ref('');
        const router = useRouter();
        const userStore = useUserStore();

        const handleLogin = async () => {
            try {
                // 这里应该调用实际的登录 API
                // 为了演示，我们使用一个模拟的登录逻辑
                if (username.value === 'admin' && password.value === 'password') {
                    await userStore.login({ username: username.value });
                    router.push('/');
                } else {
                    error.value = '用户名或密码错误';
                }
            } catch (err) {
                error.value = '登录失败，请稍后再试';
            }
        };

        return {
            username,
            password,
            error,
            handleLogin
        };
    }
};
</script>

<style scoped>
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f2f5;
}

.login-container {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

button {
    width: 100%;
    padding: 12px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #40a9ff;
}

.error-message {
    color: #ff4d4f;
    text-align: center;
    margin-top: 20px;
}

.info-message {
    color: #52c41a;
    text-align: center;
    margin-top: 20px;
}
</style>