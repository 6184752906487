<template>
    <div class="service">
        <h1>服务 {{ $route.params.type }}</h1>
        <p>这是服务 {{ $route.params.type }} 的详情页面。</p>
    </div>
</template>

<script>
export default {
    name: 'Service'
}
</script>