<template>
    <nav class="navbar navbar-left">
        <div class="navbar-container">
            <div class="logo">Logo</div>
            <ul class="nav-links">
                <li><router-link to="/" active-class="active">首页</router-link></li>
                <li class="dropdown">
                    <a href="#" @click.prevent="toggleDropdown('products')">产品 <span class="arrow">▼</span></a>
                    <ul v-show="openDropdown === 'products'" class="dropdown-menu">
                        <li><router-link to="/products/1" active-class="active">产品1</router-link></li>
                        <li><router-link to="/products/2" active-class="active">产品2</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="#" @click.prevent="toggleDropdown('services')">服务 <span class="arrow">▼</span></a>
                    <ul v-show="openDropdown === 'services'" class="dropdown-menu">
                        <li><router-link to="/services/a" active-class="active">服务A</router-link></li>
                        <li><router-link to="/services/b" active-class="active">服务B</router-link></li>
                    </ul>
                </li>
                <li><router-link to="/about" active-class="active">关于我们</router-link></li>
                <li><router-link to="/login" active-class="active">登录</router-link></li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'LeftNavbar',
    setup() {
        const openDropdown = ref(null);

        const toggleDropdown = (menu) => {
            openDropdown.value = openDropdown.value === menu ? null : menu;
        };

        return {
            openDropdown,
            toggleDropdown
        };
    }
}
</script>

<style scoped>
/* 左侧导航栏特定样式 */
.navbar-left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    background-color: var(--navbar-bg);
    color: var(--navbar-text);
    z-index: 1000;
}

.navbar-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.nav-links {
    flex-direction: column;
}

.nav-links li {
    margin-bottom: 10px;
}

/* 其他样式保持不变 */
</style>