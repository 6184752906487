<template>
    <nav class="navbar navbar-top">
        <div class="navbar-container">
            <div class="logo">
                <span class="logo-text">Your Brand</span>
            </div>
            <ul class="nav-links">
                <TopNavbarItem v-for="item in menuItems" :key="item.name" :item="item" :level="0" />
                <li v-if="isAuthenticated" class="user-info">
                    <span>{{ username }}</span>
                    <button @click="logout" class="logout-btn">注销</button>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '../store';
import TopNavbarItem from './TopNavbarItem.vue';

export default {
    name: 'TopNavbar',
    components: {
        TopNavbarItem
    },
    setup() {
        const userStore = useUserStore();
        const router = useRouter();

        const isAuthenticated = computed(() => userStore.isAuthenticated);
        const username = computed(() => userStore.user?.username || '');

        const logout = () => {
            userStore.logout();
            router.push('/login');
        };

        const menuItems = [
            { name: 'home', title: '首页', path: '/' },
            {
                name: 'products',
                title: '产品',
                children: [
                    {
                        name: 'category1',
                        title: '类别1',
                        children: [
                            { name: 'product1', title: '产品1-1', path: '/products/1' },
                            { name: 'product2', title: '产品1-2', path: '/products/2' }
                        ]
                    },
                    {
                        name: 'category2',
                        title: '类别2',
                        children: [
                            { name: 'product3', title: '产品2-1', path: '/products/3' },
                            { name: 'product4', title: '产品2-2', path: '/products/4' }
                        ]
                    }
                ]
            },
            {
                name: 'services',
                title: '服务',
                children: [
                    { name: 'serviceA', title: '服务A', path: '/services/a' },
                    { name: 'serviceB', title: '服务B', path: '/services/b' }
                ]
            },
            { name: 'about', title: '关于我们', path: '/about' },
            { name: 'project', title: '项目', path: '/project' },
            { name: 'subapp', title: '微应用Demo', path: '/subapp' },
        ];

        return {
            menuItems,
            isAuthenticated,
            username,
            logout
        };
    }
}
</script>

<style scoped>
/* 保持原有样式不变 */
.navbar-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 70px;
    width: 100%;
    /* 修改这里：从 max-width 改为 width */
    margin: 0 auto;
    box-sizing: border-box;
    /* 添加这行，确保 padding 不会增加总宽度 */
}

.logo-text {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

.nav-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* 新增用户信息和注销按钮样式 */
.user-info {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.user-info span {
    margin-right: 10px;
    color: #333;
}

.logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logout-btn:hover {
    background-color: #c0392b;
}

@media (max-width: 768px) {
    .navbar-container {
        flex-direction: column;
        height: auto;
        padding: 15px;
    }

    .nav-links {
        flex-direction: column;
        width: 100%;
    }

    .user-info {
        margin-top: 10px;
        margin-left: 0;
    }
}
</style>