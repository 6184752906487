import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { registerMicroApps, start } from "qiankun";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);

app.mount("#app");

// qiankun 配置
registerMicroApps([
  {
    name: "subapp",
    entry: "//vue-sub-project-demo.pages.dev/",
    container: "#subapp-viewport",
    activeRule: "/subapp",
  },
]);

start();
