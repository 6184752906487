<template>
    <div class="project">
        <h1>项目信息</h1>
        <div class="info-section">
            <h2>配置信息</h2>
            <div class="info-grid">
                <div class="info-item">
                    <h3>网站访问模式</h3>
                    <p>{{ config.isOpenAccess ? '开放式' : '封闭式' }}</p>
                    <p class="description">控制网站是否需要登录才能访问</p>
                </div>
                <div class="info-item">
                    <h3>导航栏显示</h3>
                    <p>{{ config.showNavbar ? '显示' : '隐藏' }}</p>
                    <p class="description">控制导航栏是否显示</p>
                </div>
                <div class="info-item">
                    <h3>导航栏位置</h3>
                    <p>{{ getNavbarPosition(config.navbarPosition) }}</p>
                    <p class="description">控制导航栏的显示位置</p>
                </div>
            </div>
        </div>
        <div class="info-section">
            <h2>全局数据</h2>
            <div class="info-grid">
                <div class="info-item">
                    <h3>用户信息</h3>
                    <p>{{ userStore.user ? userStore.user.username : '未登录' }}</p>
                    <p class="description">当前登录用户的信息</p>
                </div>
                <div class="info-item">
                    <h3>认证状态</h3>
                    <p>{{ userStore.isAuthenticated ? '已认证' : '未认证' }}</p>
                    <p class="description">用户是否已登录</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '../store';
import { config } from '../config';

export default {
    name: 'Project',
    setup() {
        const userStore = useUserStore();

        const getNavbarPosition = (position) => {
            const positions = {
                top: '顶部',
                left: '左侧',
                bottom: '底部'
            };
            return positions[position] || position;
        };

        return {
            config,
            userStore,
            getNavbarPosition
        };
    }
}
</script>

<style scoped>
.project {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

h1 {
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
}

.info-section {
    margin-top: 30px;
}

h2 {
    color: #34495e;
    margin-bottom: 20px;
}

.info-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.info-item {
    flex: 0 1 auto;
    min-width: 200px;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 {
    color: #2980b9;
    margin-top: 0;
}

p {
    margin: 10px 0;
    color: #333;
}

.description {
    font-size: 0.9em;
    color: #7f8c8d;
    font-style: italic;
}

@media (max-width: 600px) {
    .info-item {
        width: 100%;
    }
}
</style>