<template>
    <div class="home">
        <h1>Home Page</h1>
    </div>
</template>

<script>
export default {
    name: 'Home'
}
</script>