import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Product from "../views/Product.vue";
import Service from "../views/Service.vue";
import About from "../views/About.vue";
import Project from "../views/Project.vue"; // 新增
import { config } from "../config";
import { useUserStore } from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: !config.isOpenAccess },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/products/:id",
    name: "Product",
    component: Product,
    meta: { requiresAuth: !config.isOpenAccess },
  },
  {
    path: "/services/:type",
    name: "Service",
    component: Service,
    meta: { requiresAuth: !config.isOpenAccess },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { requiresAuth: !config.isOpenAccess },
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
    meta: { requiresAuth: !config.isOpenAccess },
  },
  // 添加一个捕获所有路由的配置
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      return { name: "Home" };
    },
    meta: { requiresAuth: !config.isOpenAccess },
  },
  {
    path: "/subapp/:pathMatch(.*)*",
    name: "Subapp",
    component: () => import("../views/Subapp.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const isAuthenticated = userStore.isAuthenticated;

  if (!config.isOpenAccess && !isAuthenticated && to.path !== "/login") {
    // 如果是封闭式网站，用户未登录，且目标路由不是登录页，则重定向到登录页
    next("/login");
  } else if (to.path === "/login" && isAuthenticated) {
    // 如果用户已登录但试图访问登录页，重定向到首页
    next("/");
  } else {
    // 其他情况正常导航
    next();
  }
});

export default router;
