<template>
    <div class="about">
        <h1>关于我们</h1>
        <p>这是一个示例的关于我们页面。</p>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>