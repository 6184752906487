<template>
    <div id="app" :class="{ [`navbar-${config.navbarPosition}`]: config.showNavbar && !isLoginPage }">
        <top-navbar v-if="config.showNavbar && config.navbarPosition === 'top' && !isLoginPage" />
        <!-- 其他导航栏组件 -->

        <main :class="{ 'with-navbar': config.showNavbar && !isLoginPage }">
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
import { useUserStore } from './store';
import { config } from './config';
import { computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TopNavbar from './components/TopNavbar.vue';
import LeftNavbar from './components/LeftNavbar.vue';
import BottomNavbar from './components/BottomNavbar.vue';

export default {
    name: 'App',
    components: {
        TopNavbar,
        LeftNavbar,
        BottomNavbar
    },
    setup() {
        const userStore = useUserStore();
        const route = useRoute();
        const router = useRouter();
        const isAuthenticated = computed(() => userStore.isAuthenticated);
        const isLoginPage = computed(() => route.name === 'Login');

        onMounted(() => {
            if (userStore.isAuthenticated && !userStore.user) {
                userStore.logout();
            }
        });

        watch(isAuthenticated, (newValue) => {
            if (!newValue && !config.isOpenAccess && route.meta.requiresAuth) {
                router.push('/login');
            }
        });

        return {
            isAuthenticated,
            config,
            isLoginPage
        };
    },
}
</script>

<style>
/* 全局样式 */
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

/* 导航栏样式 */
.navbar-top main.with-navbar {
    padding-top: 70px;
    /* 这个值应该与顶部导航栏的高度相同 */
}

.navbar-left main.with-navbar {
    padding-left: 200px;
    /* 根据左侧导航栏的宽度调整 */
}

.navbar-bottom main.with-navbar {
    padding-bottom: 70px;
    /* 根据底部导航栏的高度调整 */
}

/* 确保主内容区域占满剩余空间 */
main {
    min-height: 100%;
    box-sizing: border-box;
}

/* 其他原有的样式保持不变 */
</style>